@import "./variables.scss";

h1 {
  font-weight: 100;
}

li {
  margin-bottom: 8px;
}
.app-container {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  // overflow-y: scroll;
}

.app-background {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0.2;
}

.page {
  animation: entrance 500ms ease-in-out;
  padding: 30px;
  max-width: 1300px;
  margin: auto;
}

.homepage {
  display: flex;
  justify-content: center;
  height: 100vh;

  &__videos {
    display: flex;
    align-items: center;
    background-color: black;
    height: 400px;
  }

  &__footer-image {
    width: 100%;
    margin-bottom: -15px;
  }

  &__vid {
    border: none;
    width: 33%;
    & iframe {
      flex: 1;
      border: none;
      outline: none;
    }
  }

  & iframe,
  img {
    width: 100%;
  }

  &__left {
    width: 50%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__intro {
    // height: 300px;
    padding: 100px;
    text-align: center;
  }

  &__image {
    & img {
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .homepage {
    &__videos {
      flex-direction: column;
      height: fit-content;
    }
    &__vid {
      width: 100%;
    }

    &__intro {
      padding: 30px;
    }
  }
}

.text-box {
  // background-color: rgba(250, 249, 249, 0.8);
  // padding: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes entrance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

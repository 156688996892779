@import "../../variables.scss";
.store {
  &__header {
    padding: 0 80px;
    background-color: $primary;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__no-results {
    color: $primary;
    animation: fadeIn 100ms;
    animation-delay: 300ms;
    animation-fill-mode: both;
  }

  &__heading {
    padding: 30px;
  }
  &__info {
    color: white;
    padding: 10px;
  }

  &__item {
    position: relative;
    width: 15%;
    min-width: 300px;
    margin: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 4px 4px 4px rgba(5, 76, 94, 0.2);
    animation: enter 800ms;
    animation-fill-mode: both;

    &__top {
      position: relative;
    }

    &__video {
      width: 100%;
      height: 60%;
      overflow: hidden;
      iframe {
        width: 100%;
        height: 70%;
        border: none;
      }
    }

    &__more-info {
      &__cta {
        border: none;
        color: $primary;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        justify-content: center;

        &:hover {
          text-decoration: underline;
        }
        &__icon {
          margin-left: 4px;
          width: 18px;

          img {
            height: 14px;
            width: 14px;
          }
        }
      }

      &__panel {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background-color: whitesmoke;
        animation: fadeIn 200ms ease-in;
      }
    }

    &__download-badge {
      &:after {
        content: "PDF";
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 10px;
        height: 30px;
        width: 30px;
        color: goldenrod;
        border: 3px solid goldenrod;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(1px);
      }
    }

    &__price {
      color: $primary;
    }

    &__text {
      padding: 10px 20px;

      h3 {
        margin-top: 0;
        font-size: 1rem;
      }
      p {
        margin-top: -8px;
        font-size: 0.8rem;
      }

      &__buttons {
        padding: 0 20px;
        padding-bottom: 20px;

        p {
          margin: 8px;
        }
      }

      &__desc {
        font-size: 0.8rem;
      }
    }

    & img {
      width: 100%;
      object-fit: cover;
      border-bottom: 1px solid #eee;
      box-shadow: 0px 1px 4px #bbb;
      user-select: none;
      -webkit-user-drag: none;
      pointer-events: none;
    }

    &__icon {
      position: absolute;
      width: 30%;
      top: 8px;
      right: 8px;
      // box-shadow: none !important;
      border-radius: 8px;
    }

    &__link {
      color: $contrastColour;
    }

    &__button {
      border: none;
      background-color: $contrastColour;
      color: white;
      width: 100%;
      border-radius: 9px;
      padding: 5px;
      cursor: pointer;

      &--selected {
        color: darkgreen;
        border: none;
        background-color: white;
        border-radius: 10px;
        outline: none !important;
        pointer-events: none;
      }

      &--remove {
        margin-left: 8px;
        color: darkred;
        border: none;
        background-color: white;
        border-radius: 10px;
        outline: none;
      }
    }
  }

  &__disclaimer {
    color: $primary;
    text-align: center;
    font-size: 0.7rem;
    background-color: #eee;
    margin-top: 0;
    padding: 8px;

    a {
      color: $primary;
      margin-left: 4px;
    }
  }

  &__filters {
    position: sticky;
    top: 100px;
    z-index: 5;
    background-color: $primary;
    height: 60px;
    padding: 20px;

    @media (min-width: 768px) {
      height: 80px;
    }
  }
}

.checkout {
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  box-shadow: 0 3px 3px #ddd;
  transition: 400ms;
  overflow: hidden;

  &__logo {
    height: 50px;
    margin-right: 16px;
    img {
      height: 100%;
    }

    &__container {
      display: flex;
      align-items: center;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;

    & input {
      margin: 8px;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #bbb;
      width: 300px;
    }

    &__address {
      & input {
        width: 40%;
      }
    }
  }
  @media (max-width: 700px) {
    .checkout {
      &__inputs {
        flex-direction: column;
      }

      &__logo {
        height: 35px;
        margin-bottom: 12px;
        &__container {
          flex-direction: column;
        }
      }
    }
  }

  &__backdrop {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }

  &__paypal {
    margin-top: 16px;
    color: $contrastColour;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }

  &__count {
    color: $contrastColour;
    font-size: 0.74rem;
    margin-top: 4px;
  }

  &__button {
    background-color: $contrastColour;
    cursor: pointer;
    border: none;
    color: white;
    padding: 15px;
    border-radius: 10px;
    &:disabled {
      opacity: 0.6;
    }

    &--small {
      color: $contrastColour;
      border: none;
      background-color: white;
      border-radius: 10px;
      outline: none !important;
    }
  }
}

.cancel-button {
  color: darkred;
  margin-top: 30px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes enter {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

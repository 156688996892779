@import "../../../variables.scss";

.filters {
  display: flex;
  position: absolute;
  width: 95%;

  &__search {
    width: 150px;
  }

  select {
    margin: 4px;
    width: 166px;
    border: none;
    outline: none;
    padding: 4px;
    border-radius: 4px;
    color: $primary;
  }

  &__inputs {
    margin-right: 8px;
  }

  &__toggle {
    background-color: white;
    border-radius: 5px;
    border: none;
    color: $primary;
    height: 30px;
    margin: 4px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__results {
    color: white;
    font-size: 0.7rem;
    margin: 4px;
  }

  input {
    margin: 4px;
    margin-right: 8px;
    padding: 8px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    outline: none;
  }

  &__filter {
    display: flex;
    align-items: center;
    color: white;
    border: 1px solid $primary;
    padding: 4px;
    border-radius: 4px;
    background-color: $primary;
    min-width: 30px;
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    cursor: pointer;

    &__button {
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      background-color: $primary;
      color: white;
      cursor: pointer;
      height: 30px;
    }
  }

  &__list {
    position: absolute;
    background-color: $primary;
    flex-wrap: wrap;
    display: flex;
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .filters {
    &__input-container {
      display: flex;
      align-items: flex-end;
    }

    &__filter {
      margin: 8px 0;
    }

    &__list {
      flex-direction: column;
      align-items: flex-start;
      left: 0;
      top: 79px;
      padding: 4px;
    }
    &__inputs {
      display: flex;
    }
  }
}

@import "../../variables.scss";

.cookie-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-top: 1px solid $primary;
  padding: 20px;
  z-index: 55;
  color: $primary;
  font-size: 0.9rem;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  width: 80vw;
  max-width: 600px;
  line-height: 1.4rem;
  display: none;

  &--active {
    display: block;
    opacity: 1;
    transition: opacity 300ms;
  }

  &__cta {
    margin: 8px;
    border: 1px solid $primary;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
      color: $primary;
    }

    &--accept {
      background-color: $primary;
      color: white;
    }
  }
}

@import "../../../variables.scss";

.more-info-overlay {
  position: fixed;
  right: 0;
  top: 0px;
  bottom: 0;
  width: 70%;
  z-index: 20;
  color: $primary;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  opacity: 0;

  img {
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
  }

  transform: translateX(100%);
  transition: 300ms ease-in-out;

  &__content {
    padding: 20px;

    &__text {
      flex: 1;
      padding: 30px;

      p {
        line-height: 1.4rem;
      }

      &__composer {
        margin-top: -10px;
      }
    }

    &__score {
      width: 50%;
      user-select: none;
      position: relative;

      &--sample {
        position: absolute;
        opacity: 0.04;
        transform: rotate(-45deg);
        top: 40%;
      }

      @media (max-width: 900px) {
        width: 100%;
      }
      img {
        width: 100%;
        margin-right: 16px;
        @media (max-width: 900px) {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    &__layout {
      margin-top: 50px;
      display: flex;
      justify-content: space-evenly;

      @media (max-width: 900px) {
        flex-direction: column-reverse;
      }
    }
  }

  &__desc {
    font-size: 0.9rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  &__video {
    height: 200px;
    @media (max-width: 768px) {
      height: 300px;
    }
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &--active {
    transform: translateX(0%);
    box-shadow: -15px 0px 17px -7px rgba(0, 0, 0, 0.75);
    opacity: 1;
  }
}

.more-info-overlay::-webkit-scrollbar {
  display: none;
}

.close-cta {
  position: absolute;
  top: 30px;
  float: right;
  align-self: flex-end;
  right: 30px;
  border: 1px solid $primary;
  color: white;
  background-color: $primary;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    background-color: white;
    color: $primary;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import "../../variables.scss";

.downloads {
  color: $primary;
  padding: 40px;

  &__item {
    color: $primary;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba($primary, 0.5);

    & td {
      padding: 10px;
    }

    & a {
      margin-left: 16px;
    }
  }

  &__logo {
    width: 50%;
    max-width: 400px;
  }
}

.button {
  background-color: $primary;
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    background-color: darkgreen;
  }
}

@import "../../variables.scss";

.carousel {
  position: relative;
  display: flex;
  list-style: none;
  align-items: center;
  overflow: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  max-width: 900px;
  // max-height: calc(80 * var(--vh));

  &__wrapper {
    position: relative;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    & img {
      width: 100%;
      user-select: none;
    }
  }

  &__chevrons {
    z-index: 25;
    top: 40%;
    img {
      height: 30px;
    }
    &--prev {
      position: absolute;
      left: 6px;
      img {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }

    &--next {
      position: absolute;
      right: 0px;
      img {
        margin-right: 0px;
      }
    }

    & button {
      background-color: transparent;
      border: none;
      font-size: larger;
      border-radius: 50%;
      cursor: pointer;
      transition: 300ms;
      color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      &:hover {
        background-color: rgba(243, 243, 243, 0.7);
      }
    }
  }

  & li {
    width: 100%;
    min-height: 1px;

    flex: 0 0 auto;
    scroll-snap-align: center;
    transition: 300ms;
  }

  &__item {
    transition: 500ms;
    overflow: hidden;
    margin: 0 15px;

    &--sample {
      position: absolute;
      opacity: 0.04;
      transform: rotate(-45deg);
      top: 40%;
    }

    &--side {
      opacity: 0.8;
      transition: 500ms;
      cursor: pointer;
    }
  }

  &__pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    &__button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 2rem;
    }
  }
}

ul {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

ul::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

@media (min-width: 900px) {
  .carousel {
    & li {
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .carousel {
    padding: 0;
    & li {
      width: 100%;
    }

    &__chevrons {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }
}
